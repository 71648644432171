
import Btn from "@/components/ui/Btn.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { getTranslatedString as _ } from "@/i18n";
import { courseIdMixin, nodeMixin } from "@/mixins";
import { LessonNode, LessonNodeState } from "@/models";
import { sameDay } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { nodeEmits, nodeProps } from "../shared";
export default defineComponent({
	name: "LessonNode",
	mixins: [courseIdMixin, nodeMixin],
	props: {
		node: {
			type: Object as PropType<LessonNode>,
			required: true,
		},
		...nodeProps,
	},

	data() {
		return {
			LessonNodeState,
		};
	},
	emits: {
		...nodeEmits,
	},
	methods: {
		onEdit() {
			this.$emit("editNode", this.node);
		},
		onDelete() {
			this.$emit("deleteNode", this.node);
		},
	},
	computed: {
		lessonTitle() {
			return this.node.title || _("course_tree.unnamed_lesson");
		},
		bodyPreview() {
			return this.node.body;
		},
		updatedOnDifferentDay() {
			return !sameDay(new Date(this.node.created), new Date(this.node.modified));
		},
	},
	// eslint-disable-next-line vue/no-unused-components
	components: { ProcessedTextFragment, Timestamp, Btn, CopyToClipboard },
});
